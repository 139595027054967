body {
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer{
  color: #fff;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}