.add-song {
  height: 350px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.error-field {
  height: 20px;
  display: inline-block;
}