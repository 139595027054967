@import '../_colors.scss';

.ArtistLink {
  a {
    color: $orange;
  }
  .info {
    background: #eee;
    width: 100px;
    height: 100px;
    position: absolute;
  }
}