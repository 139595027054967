@import '../../../colors';

.FeedSong {
  display: flex;
  color: #fff;

  &:hover .icon {
    color: $orange-2;
  }

  .icon {
    font-size: 20px;
    padding: 5px 10px;
    color: $secondary-1;
  }
  .song-details {
    display: flex;
    flex: 1;
    flex-direction: column;

    .artist {
      flex: 2;
      display: flex;
      align-items: center;
    }
    .songname {
      flex: 4;
      display: flex;
      align-items: center;
    }
  }
}

@media (min-width: 992px) {
  .FeedSong .song-details {
    flex-direction: row;
  }
}