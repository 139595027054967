.usersName {
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.picPlaceholder {
  min-height: 100px;
  height: calc(100% - 27px);
  background-size: cover;
  background-position: center;
}