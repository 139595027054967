@import '../../_colors';

.page-artist {
  .cover {
    img {
      width: 100%;
    }
  }
  .information-panel {
    background: $gray75;
    .profile-pic {
      img {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 576px) {
    .information-panel {
      margin-top: -150px;
    }
  }
}