@import '_colors';
@import '../node_modules/animate.css/animate.min.css';

body{
  background-color: $background-color;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  color: $light-gray;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

@media (min-width: 768px){
  .bd-links {
    display: block!important;
  }
}

@media (max-width: 767px){
  .bd-links{
    position: fixed;
    z-index: 10000;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;

  }
  .bd-links header{
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    padding: .5rem .5rem;
  }
}
.bd-links .list-group .fa{
  width: 25px;
  text-align: center;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

/* loading */
.loading-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* /loading */

.fas.outline {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: $gray;
}

.btn-like,
.btn-like:focus {
  background: transparent;
  color: #fff;
  padding: 15px 0px;
  box-shadow: 0px 0px 3px $light-gray;
  &.active {
    background: $orange;
  }
}
.text-secondary-1 {
  color: $secondary-1;
}
.color-orange {
  color: $orange;
}
a.color-orange:hover {
  color: $orange-2;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.btn-danger {
  background-color: $secondary-1;
  border-color: $secondary-1;
}
.btn-danger:hover {
  background-color: $primary-1;
}
// $light-gray

// overwrite boostrap
.alert-success {
  background: #0e441b;
  border-color: #23923d;
}