@import '../../_colors';

.page-artists {
  .widget-user .widget-user-image>img {
    height: 90px;
    object-fit: cover;
  }
  .widget-user-header {
    padding: 0px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .widget-user .card-footer {
    background: $gray;
    color: #fff;
  }

  .letter {
    position: relative;

    >div {
      background: #000;
      display: inline-block;
      padding: 10px;
      text-transform: capitalize;
      color: $gray;
      font-size: 25px;
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      background: $gray75;
      top: calc(50% - 1px);
      z-index: -1;
      left: 0;
    }
  }
  .card-footer {
    background: $gray;
    color: #fff;
  }
}