@import '../../_colors';

.add-item{
  .input-group{
    border: 0px;
  }
  input{
    background: $gray;
    border: 0px;
  }
  button{
    background: $secondary-1;
    color: $light-gray;
    border: 0px;
  }
}