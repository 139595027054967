.SuggestSongButton {
  .btn {
    line-height: 1;
  }
  .btn.add-song-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}