@import '../../_colors';

.cursor-pointer{
  cursor: pointer;
}

.song{
  color: $light-gray;
  padding-top: 10px;
  padding-bottom: 10px;

  .details{
    padding-top: 15px;
  }
  .play-button{
    font-size: 50px;
    color: $secondary-1;
    padding-right: 10px;
  }
  .like-song-button{
    font-size: 50px;
    color: $gray;
    border-left: 1px solid $gray;
    padding: 0px 10px;
    // 
    display: flex;
    align-items: center;
    padding: 0px 25px;
  }
  .like-song-button.isLoading{
    padding: 0px 19px;
  }
  .like-song-button .liked {
    color: orange;
  }
  + .song{
    border-top: 1px solid $gray;
  }
}
.date + .song{
  border-top: 0px;
}

.song.expanded{
  height: 355px;
  transition: height 0.25s linear;
}

.minimize{
  height: 0px;
  overflow: hidden;
}

.feed-user-link{
  display: inline-block;
  color: $light-gray;
  font-weight: bold;
  margin-left: 5px;
}

.feed-user-pic{
  width: 15px;
  display: inline-block;
  margin-left: 5px;
}