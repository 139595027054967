@import '../../colors';

.LoginPage {
  .pic {
    // background
    background-size: cover;
    background-position: center center;
  }
  .error-placeholder {
    height: 25px;
  }
  .invalid-feedback {
    min-height: 5px;
    display: block;
    margin-bottom: 5px;
  }
  .row-wrapper {
    min-height: 500px;
    margin-bottom: 5px;
    box-shadow: 0px 0px 15px 1px $gray75;
    border-radius: 15px;
    overflow: hidden;
  }
}