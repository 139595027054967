@import '../../colors';

.multistep {
  ul {
    list-style: none;
    display: flex;
    padding: 0;
  }
  li {
    flex: 1;
    padding: 0;
    text-align: center;
    position: relative;

    >div {
      padding-top: 10px;
    }

    &.active {
      i {
        // border: 1px solid $light-gray;
        background: $secondary-1;
      }
    }
    &.checked {
      i {
        background: $primary-1;
      }
    }

    i {
      background: $gray;
      padding: 15px;
      border-radius: 50px;
      color: $light-gray;
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: $gray;
      position: absolute;
      left: -50%;
      top: 23px;
      z-index: -1;
    }
    &:first-child::after {
      content: none;
    }
  }
}