.edit-profile {
  .preview .description {
    white-space: pre-line;
  }
  .profile-pic {
    max-width: 150px;
    height: 150px;
    background: #313634;
    border-radius: 5px;
    border: 3px solid #000001;
    outline: 1px solid #313634;
    display: flex;
    align-items: center;
    overflow: hidden;
    >div {
      text-align: center;
      flex: 1;
    }
  }
}