@import '../../_colors';

.date{
  color: $light-gray;
  font-size: 23px;
  text-transform: uppercase;
  padding-top: 25px;
  border-bottom: 1px solid $light-gray;
}
.loading{
  text-align: center;
  height: calc(100vh - 270px);
  vertical-align: middle;
}
.loading i{
  line-height: calc(100vh - 270px);
}