@import '../_colors';

.list-group{
  background-color: $gray;
  border-radius: 5px;
}

.list-group-item{
  background: transparent;
  color: $light-gray;
}

.sidebarIcon {
  width: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}