.song-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1035;
  background: rgba(30, 30, 30, 0.88);
  width: 100%;
  height: 100%;

  .container {
    background: #000;
    min-height: 100%;

    nav {
      margin-left: -6.5px;
      margin-right: -6.5px;
    }
  }
}

.minimized-player {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 450px;
  height: 306px;
  z-index: 10;
  max-width: 100vw;
}