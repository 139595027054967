@import '../../../_colors.scss';

.FeedWrapper {
  .tabs {
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 15px;
    
    li {
      float: left;

      a {
        padding: 7.5px;
        margin-right: 10px;
        color: $secondary-1;
        
        &.active {
          border-bottom: 5px solid $primary-1;
        }
      }
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}