@import '../_colors';

.breadcrumbs {
  ul {
    list-style: none;
    padding: 0px;

    li {
      display: inline;
      margin: 0px 3px;
      font-size: .9em;

      a {
        color: $light-gray;
        text-decoration: underline;
      }
      .fa-chevron-right {
        font-size: .7em;
      }
    }
  }
}